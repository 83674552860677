import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header className="header">
    <div className="logo d-flex flex-column">
        <Link to="/home">  {/* Wrap the logo in a Link */}
        <img src={`${process.env.PUBLIC_URL}/media/ravid_logo.png`} alt="Logo" />
        </Link>
        <span className='text-white'>R.A.V.I.D.</span>
      </div>
      {/* <div className="nav-container">
        <nav>
          <ul className="nav-links">
            <li><Link to="/about">About</Link></li>
            <li><Link to="/account">Account</Link></li>
            <li><Link to="/collaborate">Collaborate</Link></li>
            <li><Link to="/sponsorship">Communities</Link></li>
          </ul>
        </nav>
      </div> */}
    </header>
  );
}

export default Header;
