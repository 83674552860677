import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="main-bg">
        <Header />
        <div className="content-container">
          <div className="left-content">
            <h1>Project R.A.V.I.D.</h1>
            <p>We hope to captivate your imagination.</p>
            <p>Project R.A.V.I.D. is coming soon.</p>
            <p>We seek your thoughts, feedback & collaboration. Stay tuned.</p>
          </div>
        </div>
        <footer className="footer">
          <p>Copyright © 2024 R.A.V.I.D. LLC. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;
